/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    background: $white;
    padding: 50px 0 30px;
}

.cta-card-container {
	.cta-card {
		background-color: $white;
		background-image:  url('/images/cssimg/bg_dots.png');
		font-family: $serif-font;
		padding: 32px 15px 16px;
		margin: 0 auto;
		max-width: 360px;
		text-align: center;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		p {
			color: $brand;
			font-size: rem-calc(30);
			font-weight: 400;
			line-height: 1.2;
		}
	}
}


.about-content {
	background-repeat: no-repeat;
}

@media screen and #{breakpoint(medium)} {
	.about-content {
		background-image: url('/images/cssimg/bg_terri_headshot_lrg.jpg');
		background-position: calc(50% + 250px) bottom;
		background-size: auto;
	}
}

@media screen and #{breakpoint(large)} {
	.about-content {
		background-position: calc(50% + 280px) bottom;
		background-size: contain;
	}
}

.simple-shifts-steps-section {
	margin-top: 42px;
	.row:last-child {
		margin-bottom: 0;
	}
}

.simple-shift-step {
	margin-bottom: 1.5em;
	.action-content {
		p {
			color: $gray;
			font-size: 1rem;
		}
		.heading {
			color: $dark-gray;
			font-weight: bold;
			font-size: rem-calc(21);
			margin-bottom: .75rem;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.simple-shift-step {
		margin-bottom: 1.5em;
		.action-content {
			margin-top: 1.5rem;
			p {
				font-size: 1.125rem;
			}
			.heading {
				font-size: rem-calc(24);
			}
		}
	}
}

.card-container {
	margin-bottom: 35px;
	.card {
		background-color: $white;
		margin: 0 auto 30px;
		box-shadow: $box-shadow;
		border: none;
		max-width: 300px;
		border-radius: $border-radius;
		.heading {
			background-image:  url('/images/cssimg/bg_dots.png');
			padding: 30px 30px 0 30px;
			position: relative;
			p.step-count {
				font-size: 3rem;
				font-family: $serif-font;
				line-height: 3rem;
				text-align: right;
				color: $brand;
				opacity: 0.3;
			}
			p.title {
				font-family: $serif-font;
				color: $brand-accent;
				text-transform: uppercase;
				font-size: 1.5rem;
			}
		}
		.content {
			padding: 12px 30px 30px;
			font-size: 1rem;
			color: $dark-gray;
		}
	}
}


@media screen and #{breakpoint(medium)} {
	.card-container {
		margin-bottom: 0;
	}
	.card {
		.content {
			min-height: 250px;
		}
	}
}


//Article section
.articles {
	ul {
		padding: 0;
		margin-top: 0;
	}
	li {
		background: none;
		line-height: 1.25;
		padding-left: 0;
		margin-bottom: rem-calc(20);
		.date {
			font-size: rem-calc(13);
			display: block;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.articles {
		li {
			float: left;
			width: 50%;
			padding-right: 5%;
			&::nth-child(2n+1) {
				clear: left;
			}
		}
	}
}


//Contact Section
.contact {
	padding: 48px 0;
}