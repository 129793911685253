/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/

.page-header {
    padding: 15px 0;
    .inner {
    	@include grid-row;
    }
	.logo {
		@include grid-col(6);
	}
	.mobile-menu-toggle {
		@include grid-col(6);
	}
	.main-nav {
		@include grid-col;
	}
	.main-nav-small {
		@include grid-col;
	}
}

@media screen and #{breakpoint(medium)} {
	.page-header {
	    padding: 20px 0;
	    .inner {
	    	margin-bottom: 120px;
	    }
	    .logo {
	    	width: grid-col(4);
	    }
	    .main-nav {
	    	width: grid-col(8);
	    }
	}
}


/*------------------------------------*\
    @group Navigation
\*------------------------------------*/

.mobile-menu-toggle {
	font-family: $alt-font;
	font-size: rem-calc(20);
	font-weight: 300;
	text-align: right;
	position: relative;
	.inner {
		display: inline-block;
	}
	a {
		margin: 10px 0 0;
		color: $white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&:hover,
		&:focus,
		&.open {
			color: $brand-alt-accent;
			text-decoration: none;
		}
		button {
			margin-left: 8px;
		}
	}
}
.mobile-menu-anchor {
	padding: 0;
}
.main-nav {
	display: none;
}
.main-nav-small {
	background-color: $white;
	font-size: 22px;
	text-align: center;
	padding: 20px 20px 35px;
	position: absolute;
	top: 118px;
	left: 0;
	right: 0;
	display: none;
	li {
		margin: 15px 0;
	}
}

@media only screen and (min-width:46.875em) {
	.mobile-menu-toggle,
	.mobile-menu-anchor,
	.main-nav-small {
		display: none !important;
	}
	.main-nav {
		// Color is default state and alt is for alternate states (selected, hover, focus, etc.). Bg is for li and anchor hovers as needed
		$nav-color: $white;
		$nav-color-alt: $brand-alt;
		$nav-child-color: $white;
		$nav-child-color-alt: $brand;
		font-family: $main-font;
		font-weight: 300;
		margin-top: 15px;
		display: block;
		a {
		    font-size: rem-calc(18);
		    font-weight: 700;
		    color: $nav-color;
		    line-height: 1.2;
		    padding: 5px 15px;
		    display: block;
		    border-radius: $border-radius;
		}
	    a:hover,
	    a:focus,
	    li.active > a {
	        color: $nav-color-alt;
	        background-color: $white;
	    }
		ul {
		    text-align: right;
		}
		li {
		    margin: 0 0 0 10px;
		    display: inline-block;
		    position: relative;
		    &:first-child {
		        margin-left: 0;
		    }
		    &.selected > a,
		    &.selected > a::after,
		    &.has-dropdown.selected > a::after {
		    	color: $nav-color-alt;
		    }
		}
	}
}

.nav-slide-in {
	@include mui-slide($direction: left, $duration: .3s);
}
.nav-slide-out {@include mui-slide(out, $direction: right, $duration: .3s);
}
/* @end Navigation */