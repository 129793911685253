/*------------------------------------*\
    @group Hero
\*------------------------------------*/
	//Add styles here for hero
/* @end Hero */

.hero {
	background-color: #344C6A;
	background-image: url('/images/cssimg/bg_puzzle.jpg');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 30px 0 80px;
	color: $white;
	h1 {
		color: $white;
	}
	p {
		font-weight: 700;
		font-size: 1.125rem;
		margin-bottom: 1.5rem;
	}
}


@media screen and #{breakpoint(medium)} {
	.hero {
		background-image: url('/images/cssimg/bg_puzzle_med.jpg');
		padding: 48px 0 120px;
		p {
			font-size: 1.25rem;
			margin-bottom: 2rem;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.hero {
		background-image: url('/images/cssimg/bg_puzzle_lrg.jpg');
		p {
			font-size: 1.5rem;
		}
	}
}
