/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	font-size: rem-calc(20);
	font-family: $sans-serif-font;
	padding: 15px 30px;
	border-radius: $border-radius;
	border: none;
	display: inline-block;
	line-height: 1.2;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}
.button.small {
	font-size: rem-calc(14);
	padding: 8px 13px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 23px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.alt {
	background: $brand-alt;
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
	}
}