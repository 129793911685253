/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

   All sections are sized in 'em' which means they will
   resize their spacing according to the font-size of the
   parent container. Smaller font-size means less spacing
   and larger font-size increases the spacing.

\*----------------------------------------------------------*/

.content-section-full {
	padding: 2em 0;
}
.intro-section {
	padding-bottom: 2em;
}
.content-section {
	padding-top: 4em;
	padding-bottom: 3.5em;
}
.content-sub-section {
	padding-bottom: 1.5em;
}

@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: 3em 0 3em;
	}
	.intro-section {
		padding-bottom: 0 0 3.5em;
	}
	.content-section {
		padding-top: 7.5em;
		padding-bottom: 6em;
	}
	.content-sub-section {
		padding-top: 3em;
		padding-bottom: 1.5em;

	}
}


