/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: $offwhite;
	font-family: $main-font;
	color: $main-color;
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 1.5rem;
	&.intro {
		font-size: rem-calc(20);
		color: $dark-gray;
	}
}
a {
	color: $link-base;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}
hr {
	margin: 20px 0;
}
/* @end */


@media screen and #{breakpoint(medium)} {
	body {
		font-size: 18px;
	}
	p {
		&.intro {
			font-size: rem-calc(24)
		}
	}
}