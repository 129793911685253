/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/

blockquote {
	margin: 20px 0 48px;
	border: 0;
	padding: 0;
	position: relative;
	.quote-wrap {
		color: $gray;
		font-family: $serif-font;
		padding: 0 0 12px 20px;
		margin: 0 0 10px;
		font-size: rem-calc(18);
		line-height: 1.5;
	}
	cite {
		font-style: normal;
		font-family: $main-font;
		color: $gray;
		line-height: 1;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		.photo {
			img {
				margin: 0 12px 0 0;
			}
		}
		.info {
			.name {
				font-weight: 700;
				font-size: 1rem;
				line-height: 1.5;
				color: $brand-alt;
				margin-bottom: 2px;
			}
			.title {
				font-size: 0.875rem;
				line-height: 1;
			}
		}

		&::before {
			content: '';
			background: url('/images/cssimg/quote-left.svg') no-repeat left top;
			height: 16px;
			width: 16px;
			display: block;
			position: absolute;
			top: -5px;
			left: -5px;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	blockquote {
		.quote-wrap {
			font-size: rem-calc(20);
		}
	}
}